import React from 'react';
import leaderboard_desk from '../assets/leaderboard_desk.png';
import leaderboard_mob from '../assets/leaderboard_mob.png';
// import leaderboard_mob2 from "../assets/leaderboard_mob2.png";

const Leaderboard = () => {
  return (
    <div>
      <p className="max-w-[414px] font-black text-[26px] text-center mt-[100px] md:text-[50px] md:leading-[65px] md:font-black md:max-w-[1076px] md:text-center text-white font-inter md:ml-[13rem] md:pb-[117px] ">
        WAGMI Points Rank-board
      </p>
      <br />
      <p className="text-[16px] text-center mt-[6px] md:mt-[-8rem] mb-[3rem] text-[#898CA9] font-inter font-normal">
        Be active on our Discord Community and earn exciting rewards by getting WAGMI Points.
      </p>
      <div className="hidden w-[100%] md:w-full md:h-full md:flex hover:-translate-y-1 hover:scale-110 transition ease-in-out">
        <img
          className="object-scale-down md:h-[640px] w-auto mx-auto "
          src={leaderboard_desk}
          alt="rankings"
        />
      </div>

      <div className="px-[20px] mr-[50px] w-[100%] mt-[50px] h-full flex-row md:hidden hover:-translate-y-1 hover:scale-110 transition ease-in-out">
        <img className="object-scale-down w-auto mx-auto " src={leaderboard_mob} alt="rankings" />
        {/* <img
          className="object-scale-down mt-[20px] w-auto mx-auto "
          src={leaderboard_mob2}
          alt="rankings"
        /> */}
      </div>
    </div>
  );
};

export default Leaderboard;
