import React from 'react';
import people from '../assets/people-building-metaverse.png';
const Connect = () => (
  <div className=" grid grid-cols-1 md:grid-cols-2 md:ml-[114px] ">
    <div className="">
      <img
        src={people}
        className="hidden md:block h-[506px] md:w-[506px] hover:-translate-y-1 md:mt-[300px] hover:scale-110 transition ease-in-out "
        alt="/"
      />
    </div>
    <div className=" md:h-screen md:pt-[118px] text-center flex flex-col">
      <p className="mt-[10rem] max-w-[414px] font-black text-[26px] mt-text-center md:mt-[300px] md:text-left md:text-[27px] md:leading-[65px] md:font-semibold md:max-w-[540px] text-white font-inter ">
        Connect with the best of
        <br className="md:hidden" /> Web3 wizards
      </p>
      <p className="pl-[100px] pt-8 text-center text-[18px] lead-[18px] font-inter font-normal max-w-[300px] md:mt-4 md:max-w-[640px] text-[#898CA9] md:pt-0 md:text-left md:text-[18px] md:pl-[0px]">
        Network with the leading Web3 experts through exclusive events and programs.
      </p>
      <a
        className="md:text-white md:bg-gradient-to-bl md:from-[#18C8FF] md:to-[#933FFE] md:hover:from-pink-500 md:hover:to-yellow-500 md:w-[170px] md:rounded-[10px] md:mx-0 md:font-inter md:font-medium md:py-4 md:mt-8 md:ease-in-out md:duration-500 md:hover:-translate-y-1"
        href="https://docs.google.com/forms/d/1npa3c6i7Qy4OjlCqqYI_DWLxB_-X2Pe_GJ56MJ3CHkA/edit"
        target="_blank"
      >
        Get first access -{`>`}{' '}
      </a>
    </div>
    <div className="">
      <img
        src={people}
        className="md:hidden h-[400px] w-[400px] hover:-translate-y-1 hover:scale-110 transition ease-in-out"
        alt="/"
      />
      <a
        className=" md:hidden text-white bg-gradient-to-bl from-[#18C8FF] to-[#933FFE] hover:from-pink-500 hover:to-yellow-500 w-[170px] rounded-[10px] mx-auto px-0 flex justify-center font-inter font-medium py-4  ease-in-out duration-500 hover:-translate-y-1"
        href="https://docs.google.com/forms/d/1npa3c6i7Qy4OjlCqqYI_DWLxB_-X2Pe_GJ56MJ3CHkA/edit"
        target="_blank"
      >
        Be a Contributor -{`>`}{' '}
      </a>
    </div>
  </div>
);
export default Connect;
