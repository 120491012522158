import React from 'react';

const Hero = () => {
  return (
    <div className="text-white">
      <div className="max-w-[800px] mt-[80px] md:mt-0 w-full pl-[20px] text-center md:h-screen mx-auto md:text-left flex flex-col justify-center mb-0">
        <h1 className="text-[36.6px] md:text-7xl leading-12 text-white text-center font-clashDisplay font-bold mt-[6rem]">
          Join the Web 3.0
          <br className="md:hidden" /> revolution
        </h1>
        <p className="text-[16px] text-center mt-[6px] md:mt-8 text-[#898CA9] font-inter font-normal">
          Be a part of India’s first student led Web3 community
        </p>
        <div className="bg-gradient-to-bl text-center from-[#18C8FF] to-[#933FFE] hover:from-pink-500 hover:to-yellow-500 w-[170px] rounded-[10px]  md:mx-auto font-inter font-medium py-4 mt-8 waitlist">
          <a
            href="https://docs.google.com/forms/d/1erX_peIhDC_TSBfpQCSLytOdOYBufxrlTdQYSwzN03M/edit"
            target="_blank"
          >
            Join the Waitlist
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
