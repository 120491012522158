import React, { useState } from 'react';
import Footer from './Footer';
import ftx from '../assets/ftx.png';
import ftx2 from '../assets/ftx2.jpg';
import vector_about from '../assets/vector_about.png';
import { useEffect } from 'react';

const NewsletterDetails = ({ newsDetails, setShowDetails }) => {
  // console.log(newsDetails);
  const DetailsData = {
    'Fall of FTX': {
      content:
        'Cryptocurrency exchange FTX and its founder and former CEO, Sam Bankman-Fried, are linked.On Dec. 12, 2022, Bankman-Fried was arrested and jailed in the Bahamas in connection with alleged fraudulent activities while running FTX, in an indictment from the U.S.Attorney for the Southern District of New York spelled out.Other investigations and lawsuits against him and the company are ongoing',
      image: ftx,
      view: 'https://drive.google.com/file/d/12Jx_D3rDoQf4RXk8yazfXATMAjVxWnw0/view?usp=sharing',
      publish: 'https://docs.google.com/forms/d/1npa3c6i7Qy4OjlCqqYI_DWLxB_-X2Pe_GJ56MJ3CHkA/edit'
    },

    'Industry & Trends Update': {
      content:
        'Since 2020, digital currencies have begun their establishment on the global market. The blockchain, cryptocurrency, metaverse, and NFT products started developing actively. Along with these technological advances, there are a lot of discussions on Web 3.0 the new generation of the internet. In 2021 about 3400 developers joined Web 3.0 projects. Here are the top 5 Web3 trends that will go big in 2023.',
      image: ftx2,
      view: 'https://drive.google.com/file/d/1qJF0chEP8qiIFg8gcZ7u7tiJZvqxSpI1/view?usp=sharing',
      publish: 'https://docs.google.com/forms/d/1npa3c6i7Qy4OjlCqqYI_DWLxB_-X2Pe_GJ56MJ3CHkA/edit'
    }
  };
  const [item, setItem] = useState();
  useEffect(() => {
    // console.log(title);
    setItem(DetailsData[newsDetails?.title]);
  }, []);

  // console.log(item?.date);
  return (
    <div className="news-page bg-black md:text-center">
      <img className="md:py-8 md:px-8" src={item?.image} alt="" />
      <div className=" md:h-screen md:pt-[38px] md:text-center text-center md:ml-[20px] flex flex-col">
        <p className="font-black text-[28px] mt-[30px] text-center  md:text-center md:text-[70px] md:leading-[65px] md:font-semibold  text-white font-clashDisplay">
          NewsLetter
        </p>
        <p className="font-black text-[16px] mt-[30px] text-center  md:text-center md:text-[35px] md:leading-[65px] md:font-semibold  text-white font-clashDisplay">
          (Initiative by{' '}
          <span className="bg-gradient-to-r text-transparent text-2xl bg-clip-text from-[#E33CD7] to-[#19C8FF]  md:bg-gradient-to-r text-transparent md:text-4xl bg-clip-text from-[#E33CD7] to-[#19C8FF] ">
            MDI - Gurgaon
          </span>{' '}
          )
        </p>
        <p className=" pt-8 text-center text-[18px] lead-[18px] font-inter font-normal  md:mt-[30px]  text-white md:pt-0 md:text-center md:text-[48px] md:pl-[0px]">
          {' '}
          {newsDetails?.title}{' '}
        </p>
        <p className="py-8 px-8 text-center text-[16px] lead-[18px] font-inter font-normal  md:mt-[30px] md: text-white md:pt-0 md:text-center md:py-8 md:px-8 md:text-[26px] md:pl-[0px]">
          {item?.content}
        </p>
        <a
          className="flex flex-row justify-center text-white bg-gradient-to-bl from-[#18C8FF] to-[#933FFE] hover:from-pink-500 hover:to-yellow-500 w-[240px] rounded-[10px] text-[16px]  mx-auto my-auto font-inter font-semibold py-4  mb-[40px] ease-in-out duration-500 hover:-translate-y-1 md:flex md:flex-row md:justify-center md:text-white md:bg-gradient-to-bl md:from-[#18C8FF] md:to-[#933FFE] md:hover:from-pink-500 md:hover:to-yellow-500 md:w-[240px] md:rounded-[10px] md:text-[16px]  md:mx-auto md:my-auto md:font-inter md:font-semibold md:py-4 md:mt-[40px] md:mb-[40px] md:ease-in-out md:duration-500 md:hover:-translate-y-1"
          href={item?.view}
          target="_blank"
        >
          View Newsletter
        </a>

        <div className=" w-[100%] md:w-[1409px] md:h-[100px] md:flex md:place-items-center ">
          <img className=" w-auto mx-auto " src={vector_about} alt="desc" />
        </div>

        <p className=" pt-8 mb-8 text-center text-[18px] lead-[18px] font-inter font-normal  md:mt-[30px]  text-white md:pt-0 md:text-center md:text-[48px] md:pl-[0px]">
          Publish your newsletter
        </p>
        <a
          className="flex flex-row justify-center text-white bg-gradient-to-bl from-[#18C8FF] to-[#933FFE] hover:from-pink-500 hover:to-yellow-500 w-[240px] rounded-[10px] text-[16px]  mx-auto my-auto font-inter font-semibold py-4  mb-[40px] ease-in-out duration-500 hover:-translate-y-1 md:flex md:flex-row md:justify-center md:text-white md:bg-gradient-to-bl md:from-[#18C8FF] md:to-[#933FFE] md:hover:from-pink-500 md:hover:to-yellow-500 md:w-[240px] md:rounded-[10px] md:text-[16px]  md:mx-auto md:my-auto md:font-inter md:font-semibold md:py-4 md:mt-[40px] md:mb-[40px] md:ease-in-out md:duration-500 md:hover:-translate-y-1"
          href={item?.publish}
          target="_blank"
        >
          Be a Contributor -{`>`}{' '}
        </a>
      </div>

      <a
        className=" flex justify-center mb-8 mx-auto py-4  w-[120px] text-white text-center bg-gradient-to-bl from-[#18C8FF] to-[#933FFE] hover:from-pink-500 hover:to-yellow-500  rounded-[10px]  md:mx-auto font-inter font-medium md:flex md:justify-center md:mt-[25rem]"
        href="/newsletter"
        onClick={() => setShowDetails(false)}
      >
        Back
      </a>

      <div className=" md: w-[100%] md:w-[1409px] md:h-[100px] md:flex md:place-items-center ">
        <img className=" w-auto mx-auto " src={vector_about} alt="desc" />
      </div>

      <Footer />
    </div>
  );
};

export default NewsletterDetails;
