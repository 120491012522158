import React from 'react';
import featuresgroup from '../assets/featuresgroup.png';
import { useHistory } from 'react-router-dom';

const Join = () => {
  const history = useHistory();
  const eventPage = () => {
    console.log('desktop');
    history.push('/events');
  };
  const eventMob = () => {
    console.log('mobile');
    history.push('/events');
  };
  return (
    <div className="flex flex-col imt-[124px] h-screen">
      <h1 className="max-w-[800px] w-full mx-auto text-[36px] mt-[120px] text-center flex flex-col justify-center md:text-[63.5px] text-white font-clashDisplay font-black ">
        Why should you <br className="md:hidden" />
        join WAGMI?
      </h1>
      <div className="mt-[3rem] grid md:grid-cols-2">
        <div className="gap-5  w-full md:h-screen mx-auto text-center flex flex-col justify-start md:pl-[140px] ">
          <p className=" max-w-[414px] font-black text-[26px] text-center md:mt-0 md:text-left md:text-[26px] md:mt-[5rem] md:leading-[65px] md:font-semibold md:max-w-[800px] text-white font-inter ">
            Unlock the world of
            <br className="md:hidden" /> unlimited opportunities{' '}
          </p>
          <p className="text-center text-[16px] lead-[18px] font-inter font-normal max-w-[1280px] md:mt-6 md:max-w-[640px] text-[#898CA9] md:text-left md:text-[18px]">
            Be an early adopter to learn & grow through our abundant resources in the domain of Web
            3.0, Blockchain and Cryptocurrencies.
          </p>
          <button
            className="md:text-white md:bg-gradient-to-bl md:from-[#18C8FF] md:to-[#933FFE] md:hover:from-pink-500 md:hover:to-yellow-500 md:w-[170px] md:rounded-[10px]  md:font-inter md:font-medium md:py-4 md:mt-8 md:ease-in-out md:duration-500 md:hover:-translate-y-1"
            onClick={eventPage}
          >
            <a>View Events</a>
          </button>
        </div>
        <div className="pl-[55px] mt-0 md:pl-[100px] flex flex-col">
          <img
            className="mt-[1px] h-[197.5px] w-[277px] md:h-[401px] md:w-[474px] hover:-translate-y-1 hover:scale-110 transition ease-in-out"
            src={featuresgroup}
            alt="/"
          />
          <button
            className="md:hidden text-white bg-gradient-to-bl from-[#18C8FF] to-[#933FFE] hover:from-pink-500 hover:to-yellow-500 w-[170px] rounded-[10px] flex flex-row justify-center font-inter font-medium py-4 mx-14 mt-8 ease-in-out duration-500 hover:-translate-y-1"
            onClick={eventMob}
          >
            <a>View Events</a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Join;
