import React, { useState } from 'react';
import Footer from './Footer';
import isbh from '../assets/isbh.png';
import mdiw from '../assets/mdiw.png';
import wagmin from '../assets/wagmin.png';
import nftt from '../assets/nftt.png';
import isbm from '../assets/isbm.png';
import faceoff from '../assets/faceoff.png';
import wallstrt from '../assets/wallstrt.png';
import quant from '../assets/quant.png';
import equity from '../assets/equity.png';
import vector_about from '../assets/vector_about.png';
import { useEffect } from 'react';

const EventDetails = ({ eventDetails, setShowDetails }) => {
  console.log(eventDetails);
  const DetailsData = {
    'NFT Event': {
      content:
        ' Educating students about wallets and NFTs. Most individuals created their first wallets. The Ambassadors solved attendees problems while creating a wallet and claiming an NFT.',
      image: isbh,
      date: '3 Aug 2022'
    },

    'WAGMI Nights': {
      content:
        'WAGMI Nights is an offline set up in each of the college, where students get together and discuss about Web3 domain, share ideas and have a snack party.',
      image: wagmin,
      date: '30 Oct 2022'
    },

    'NFT Trivia': {
      content:
        "FanTiger is India's largest NFT marketplace. NFTs possess a unique ability to create value for both digital and real world entities. They bridge the gap between digital and physical, and allow for abstract ideas, events and concepts to suddenly have tangible commercial worth. We all know that NFT's derive their value from three foundational pillars - Community, Culture and Utility.The Trivia entailed to educate the student community about the same and as a token of reward top 6 winners got  Airdrop FanTiger NFTs.",
      image: nftt,
      date: '29 Nov 2022'
    },

    'Blockchain Event': {
      content:
        'Blockchain sesion being a resounding success, the Ambassadors received requests for more such sessions. One of the Ambassadors provided a session on the emerging Blockchain technology, explaining the fundamentals, present scenario and the future prospects of it, including the various opportunities coming in this domain.',
      image: isbm,
      date: '4 Aug 2022'
    },
    'AMA session': {
      content:
        'It was a very insightful session covering the various aspects of Web3 including explanations on Web1, Web2 and blockchain concepts. Mr Pranjal also talked about the problems associated with centralised platforms and how decentralisation will help. Keeping the BSchool audience in the mind, he related the concepts of businesses with  the Web3 and also talked about how Web3.0 will impact the marketing strategies and the world of Finance. Last but not the least, he talked about a new concept called ZK rollups which was something very interesting to know about.',
      image: mdiw,
      date: '3 Dec 2022'
    },
    'Wall Street Trading Competition': {
      content:
        "IIT Kanpur hosted a Wall Street Trading Comeptition which saw a traction of 500+ students over a span of 3 days. Trading was done in a variety of assets, cryptocurrency coins for three days with the leaderboards being updated after everyday's trades.",
      image: wallstrt,
      date: '8 Jan 2023'
    },
    'Equity Research Challenge': {
      content:
        'Hosted by IIT Guwahati, the competition includes three stages namely Finance Quiz round, Equity report submission and Presentation round. Top 5 teams would be invited to present their reports to the Jury from Mudrex where the winner will be fighting for prizes worth 1,00,000 INR.  ',
      image: equity,
      date: '27 Jan 2023'
    },
    'Quant research Project': {
      content:
        'A general real life problem statement which should be such that the implementation and solution can be carried out individually as an algo trading project which would be ideal to learn and build something of real value within the project duration.',
      image: quant,
      date: '31 Jan 2023'
    },
    'College Face-offs': {
      content:
        'Facilitating face-offs between colleges on the form of trivia, case competition, extempore and various other competitions.',
      image: faceoff,
      date: '19 Dec 2022'
    }
  };
  const [item, setItem] = useState();
  useEffect(() => {
    // console.log(title);
    setItem(DetailsData[eventDetails?.title]);
  }, []);

  // console.log(item?.date);
  return (
    <div className="flex flex-col content-center text-center bg-black">
      <div>
        <div>
          <img
            className="w-100% place-content-center mx-auto pt-8"
            src={item?.image}
            alt="details"
          />
        </div>
        <div>
          <p className="mt-6 mx-auto place-content-center max-w-[414px] font-black text-[26px]  text-center md:text-[50px] md:leading-[65px] md:font-black md:max-w-[1076px] md:text-center md:pt-[70px] text-white font-inter  ">
            {eventDetails?.title}
          </p>
        </div>
        <div className="content-center px-6 py-1 text-sm font-semibold text-gray-700 mt-8 mb-1">
          {/* {console.log(item?.date)} */}
          <span class=" bg-gray-200 rounded-full px-6 py-1">{item?.date}</span>
        </div>
        <div className="text-white px-6 py-6 text-[25px]">{item?.content}</div>
      </div>

      <a
        className="content-center mb-8 mx-auto py-4 mt-8 w-[120px] text-white text-center bg-gradient-to-bl from-[#18C8FF] to-[#933FFE] hover:from-pink-500 hover:to-yellow-500  rounded-[10px]  md:mx-auto font-inter font-medium py-4 mt-8 mb-8 "
        href="/events"
        onClick={() => setShowDetails(false)}
      >
        Back
      </a>

      <div className="md:mt-[40px] w-[100%] md:h-[100px] md:flex md:place-items-center ">
        <img className=" w-auto mx-auto " src={vector_about} alt="desc" />
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default EventDetails;
