import React from 'react';

function Mail() {
  return (
    <div className="flex flex-col items-center mt-[10rem] mb-52 gap-3">
      <p className=" font-black text-[56px] mt-[54.76px]text-center md:text-[78px] md:leading-[65px] md:font-black md:text-center text-white font-inter md:pb-[25px] ">
        Mail Us
      </p>
      <p className="px-8 max-h-[96px] text-[25px] font-inter text-[#898CA9] text-center font-normal">
        bring your college on the map of Web3.0 with WAGMI
      </p>
      <a
        className="text-white text-center bg-gradient-to-bl from-[#18C8FF] to-[#933FFE] hover:from-pink-500 hover:to-yellow-500 w-[170px] rounded-[10px] md:mx-auto font-inter font-medium py-4 mt-8"
        href="https://docs.google.com/forms/d/1MzwawBTJ1vxTdvWLrLr0eefStB3TjhTfADIZTnnM1UY/edit"
        target="_blank"
      >
        Enter your Details
      </a>
    </div>
  );
}

export default Mail;
