import Hero from './Hero';
import Colleges from './Colleges';
import Join from './Join';
import Connect from './Connect';
import Build from './Build';
import Exclusive from './Exclusive';
import Leaderboard from './Leaderboard';
import Testimonials from './Testimonials';
import Mail from './Mail';
import Footer from './Footer';
import React from 'react';
import Newsletters from './Newsletters';

const Home = () => {
  return (
    <div className="main-page">
      <Hero />
      <Colleges />
      <Join />
      <Connect />
      <Build />
      <Exclusive />
      <Leaderboard />
      <Testimonials />
      <Mail />
      <Footer />
    </div>
  );
};

export default Home;
