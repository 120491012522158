import React from 'react';
import people from '../assets/metaverse-gate.png';

const Build = () => {
  return (
    <div className="mt-16 grid grid-cols-1 md:grid-cols-2 md:ml-[114px]">
      <div className=" md:h-screen md:pt-[118px] text-center flex flex-col">
        <p className=" max-w-[414px] font-black text-[26px]  text-center  md:text-left md:text-[27px] md:leading-[65px] md:font-semibold md:max-w-[640px] text-white font-inter ">
          Build your career in the
          <br className="md:hidden" /> domain
        </p>
        <p className="pl-[100px] pt-8 text-center text-[16px] lead-[18px] font-inter font-normal max-w-[300px] md:mt-4 md:max-w-[640px] text-[#898CA9] md:pt-0 md:text-left md:text-[20px] md:pl-[0px]">
          Become a WAGMI fellow & unlock access to assistance assisstance to building your career
          and receiving opportunities in the space of Web 3.0
        </p>
        <button className="hidden md:flex md:flex-row md:justify-center md:text-white md:bg-gradient-to-bl md:from-[#18C8FF] md:to-[#933FFE] md:hover:from-pink-500 md:hover:to-yellow-500 md:w-[240px] md:rounded-[10px] md:text-[16px]  md:mx-0 md:font-inter md:font-semibold md:py-4 md:mt-[60px] md:ease-in-out md:duration-500 md:hover:-translate-y-1">
          <a
            href="https://docs.google.com/forms/d/1bG5zOAPiuC9ZLY7VrOsbEJFq_vLYZ-cod7zDDB0zFmY/edit"
            target="_blank"
          >
            Career Opportunities -{`>`}{' '}
          </a>
        </button>
      </div>

      <div className="">
        <img
          src={people}
          className="hidden md:block h-[506px] md: md:w-[506px] hover:-translate-y-1 hover:scale-110 transition ease-in-out"
          alt="/"
        />
      </div>
      <div className="">
        <img
          src={people}
          className="md:hidden h-[412px] w-[412px] hover:-translate-y-1 hover:scale-110 transition ease-in-out"
          alt="/"
        />
        <button className="flex flex-row justify-center text-white bg-gradient-to-bl from-[#18C8FF] to-[#933FFE] hover:from-pink-500 hover:to-yellow-500 w-[240px] rounded-[10px] text-[16px] mx-auto md:mx-0 font-inter font-semibold py-4 mt-4 ease-in-out duration-500 hover:-translate-y-1 md:hidden">
          <a
            href="https://docs.google.com/forms/d/1bG5zOAPiuC9ZLY7VrOsbEJFq_vLYZ-cod7zDDB0zFmY/edit"
            target="_blank"
          >
            Career Opportunities -{`>`}{' '}
          </a>
        </button>
      </div>
    </div>
  );
};

export default Build;
