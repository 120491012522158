import React from 'react';
import wagmi_desk from '../assets/WAGMI_desk.png';
import wagmi_mob from '../assets/WAGMI_mob.png';
import vector_about from '../assets/vector_about.png';
import globe_about from '../assets/globe_about.png';
//import globe2_about from '../assets/globe2_about.png'
import web3_about from '../assets/graphic_about.png';
//import astro_about from '../assets/astro_about.png'
import about_ppl from '../assets/about_ppl.png';
import about_moreppl from '../assets/people_about.png';
import gifts_about2 from '../assets/gifts_about2.png';
import about_rocket from '../assets/about_rocket.png';
import about_wagmigift from '../assets/about_wagmigift.png';
import about_wagmippl from '../assets/about_wagmippl.png';
import who_mob from '../assets/who_mob.png';
import rocket_mob from '../assets/rocket_mob.png';
import Footer from './Footer';

const About = () => {
  return (
    <div className="about-page">
      <div className="hidden w-100% justify-center md:flex ">
        <img
          className="object-scale-down md:items-center md:mt-[61px] w-100%"
          src={wagmi_desk}
          alt="desc"
        />
      </div>

      <div className="px-[20px] mr-[50px] w-[100%] mt-[50px] h-full flex-row md:hidden hover:-translate-y-1 hover:scale-110 transition ease-in-out">
        <img className="md:hidden object-scale-down w-auto mx-auto " src={wagmi_mob} alt="desc" />
      </div>

      <div className=" mt-[30px] w-[100%] md:w-[1300px] md:h-[100px] md:flex md:place-items-center ">
        <img className=" w-auto mx-auto " src={vector_about} alt="desc" />
      </div>

      <div>
        <div className="md:pt-[118px] mt-8 text-center flex flex-col items-center">
          <p className=" max-w-[414px] mt-[20px] font-black text-[26px] mt-text-center md:mt-[-120px] md:text-left md:text-[32px] md:leading-[65px] md:font-semibold md:max-w-[540px] text-white font-inter ">
            Who are we
          </p>
          <p className=" pt-8 text-center text-[15px] lead-[18px] font-inter font-normal max-w-[300px] md:mt-0 md:max-w-[640px] text-[#898CA9] md:pt-0 md:text-center md:text-[20px] md:pl-[0px]">
            # We are All Gonna Make It
          </p>
          <p className="pt-8 text-center text-[16px] lead-[18px] font-inter font-normal max-w-[300px] md:mt-4 md:max-w-[1040px] text-white md:pt-0 md:text-center md:text-[24px]  md:pl-[0px]">
            A community of students sharing their enthusiasm and belief towards the world of Web 3.0
            and its principles of transparency and equal accessibility.{' '}
          </p>
        </div>

        <div className="flex flex-row justify-center">
          <div className="w-[100%] md:w-[1409px]  md:flex justify-start">
            <img
              className="who-mob object-scale-down md:h-[1040px] mt-[100px] margin-left-0 w-[100%] mx-auto "
              src={who_mob}
              alt="desc"
            />
          </div>
        </div>

        <div className="flex flex-row justify-center">
          <div className="hidden w-[100%] md:w-[1409px] md:h-[768px] md:flex justify-start">
            <img
              className="object-scale-down md:h-[1040px] mt-[-230px] margin-left-0 w-200% mx-auto "
              src={about_ppl}
              alt="desc"
            />
          </div>
        </div>
      </div>

      <div className=" mt-[110px] w-[100%] md:w-[1300px] md:h-[10px] md:flex md:place-items-center ">
        <img className=" w-auto mx-auto " src={vector_about} alt="desc" />
      </div>

      <div className="mt-14 grid grid-cols-1 md:grid-cols-2 md:ml-[115px] md:mt-72 flex flex-col">
        <div className=" md:h-screen md:pt-[118px] text-center flex flex-col">
          <p className="max-w-[414px]  font-black text-[26px] mt-text-center md:mt-[-190px] md:text-left md:text-[32px] md:leading-[65px] md:font-semibold md:max-w-[540px] text-white font-inter">
            What does WAGMI Club do?
          </p>
          <p className="ml-[3rem] pt-8 text-center text-[16px] lead-[18px] font-inter font-medium max-w-[300px] md:ml-0 text-left mt-4 md:max-w-[640px] text-white md:pt-0 md:text-left md:text-[20px] md:pl-[0px]">
            It provides a platform to all the students who are eager to explore the Web 3.0 space.
            To make this possible, WAGMI collaborates with institutions and companies to bridge the
            gap between them and enhance the learning experience, making it seamless for everyone.
          </p>
        </div>
        <div className="w-[100%]">
          <img
            src={rocket_mob}
            className=" md:block md:h-[521px] md:w-[521px] hover:-translate-y-1 md:mx-[70px] md:mt-[-190px] hover:scale-110 transition ease-in-out "
            alt="/"
          />
        </div>
      </div>

      <div className=" w-[100%] md:w-[1300px] md:h-[10px] md:flex md:place-items-center ">
        <img className=" w-auto mx-auto " src={vector_about} alt="desc" />
      </div>

      <div className="mt-14 grid grid-cols-1 md:grid-cols-2 md:ml-[114px] md:mt-72 flex flex-col-reverse">
        <div className=" md:h-screen md:pt-[118px] text-center flex flex-col">
          <p className="max-w-[414px]  font-black text-[26px] mt-text-center md:mt-[-190px] md:text-left md:text-[26px] md:leading-[65px] md:font-semibold md:max-w-[540px] text-white font-inter">
            Learn, collaborate, earn rewards together
          </p>
          <p className="ml-[3rem] pt-8 text-center text-[16px] lead-[18px] font-inter font-medium max-w-[300px] md:ml-0 text-left mt-4 md:max-w-[500px] text-white md:pt-0 md:text-left md:text-[20px] md:pl-[0px]">
            Get exclusive access to access to case competitions, merchandise, panel discussions,
            AMAs, mentorship sessions with industry experts, career opportunities, live projects,
            Airdrops etc.
          </p>
        </div>

        <div className="">
          <img
            src={about_wagmippl}
            className=" md:block h-[565px] md:w-[565px] hover:-translate-y-1 md:mt-[-190px] hover:scale-110 transition ease-in-out "
            alt="/"
          />
        </div>
      </div>

      <div className="w-[100%] md:w-[1300px] md:h-[10px] md:flex md:place-items-center ">
        <img className=" w-auto mx-auto " src={vector_about} alt="desc" />
      </div>

      <div className="mt-14 grid grid-cols-1 md:grid-cols-2 md:ml-[115px] md:mt-72">
        <div className=" md:h-screen md:pt-[118px] text-center flex flex-col">
          <p className="max-w-[414px] font-black text-[26px] mt-text-center md:mt-[-190px] md:text-left md:text-[32px] md:leading-[65px] md:font-semibold md:max-w-[540px] text-white font-inter ">
            Participate & Earn
          </p>
          <p className="ml-[3rem] pt-8 text-center text-[16px] lead-[18px] font-inter font-medium max-w-[300px] md:ml-0 mt-4 md:max-w-[500px] text-white md:pt-0 md:text-left md:text-[20px] md:pl-[0px]">
            Participate in online as well as offline events and earn exclusive access to Web3
            networking events, vouchers, pop-sockets, T-shirts, headphones, Web 3.0 courses and
            fellowships and many more such opportunities.
          </p>
        </div>
        <div className="">
          <img
            src={about_wagmigift}
            className="md:block md:h-[560px] md:w-[591px] hover:-translate-y-1 md:mx-[0px] md:mt-[-200px] hover:scale-110 transition ease-in-out "
            alt="/"
          />
        </div>
      </div>

      <div className=" w-[100%] md:w-[1300px] md:h-[10px] md:flex md:place-items-center ">
        <img className=" w-auto mx-auto " src={vector_about} alt="desc" />
      </div>

      <div className="mt-[50px]">
        <Footer />
      </div>
    </div>
  );
};

export default About;
