import React, { useState } from 'react';
import { FaBars, FaWindowClose } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../assets/logo_trans.png';

const Navbar = () => {
  const pathname = window.location.pathname;
  const isEvent = pathname.includes('/events');
  const [nav, setNav] = useState(true);
  const [SelectedId, setSelectedId] = useState('Home');
  const handleNav = () => {
    setNav(!nav);
  };

  const handleSelect = (id) => {
    setSelectedId(id);
  };

  return (
    <div className="flex justify-between items-center h-20  mx-auto px-4 text-white bg-gradient-to-r from-[#1B1B1B] to-[#000000]">
      <h1 className="w-full text-3xl font-bold text-{#00df9a}">
        <Link to={'/'}>
          <img src={logo} className="w-[30%] md:w-[30%]" alt="logo" />
        </Link>
      </h1>
      <ul className="hidden md:flex">
        <li
          className="p-4 transition ease-in-out delay-80 hover:-translate-y-1 hover:scale-110 font-inter"
          id="home"
          style={{ color: SelectedId === 'home' ? '#933FFE' : 'white' }}
          onClick={() => handleSelect('home')}
        >
          <Link to={'/'}>Home</Link>
        </li>
        <li
          className="p-4 transition ease-in-out delay-80 hover:-translate-y-1 hover:scale-110 font-inter"
          id="about"
          style={{ color: SelectedId === 'about' ? '#933FFE' : 'white' }}
          onClick={() => handleSelect('about')}
        >
          <Link to={'/about'}>About</Link>
        </li>
        <li
          className="p-4 transition ease-in-out delay-80 hover:-translate-y-1 hover:scale-110 font-inter"
          id="event"
          style={{ color: SelectedId === 'event' ? '#933FFE' : 'white' }}
          onClick={() => handleSelect('event')}
        >
          <Link to={'/events'}>Events</Link>
        </li>
        <li
          className="p-4 transition ease-in-out delay-80 hover:-translate-y-1 hover:scale-110 font-inter"
          id="trail"
          style={{ color: SelectedId === 'trail' ? '#933FFE' : 'white' }}
          onClick={() => handleSelect('trail')}
        >
          <Link to={'/trailblazers'}>Trailblazers</Link>
        </li>
        <li
          className="p-4 transition ease-in-out delay-80 hover:-translate-y-1 hover:scale-110 font-inter"
          id="merch"
          style={{ color: SelectedId === 'merch' ? '#933FFE' : 'white' }}
          onClick={() => handleSelect('merch')}
        >
          <Link to={'/merch'}>Merch</Link>
        </li>
        <li
          className="p-4 transition ease-in-out delay-80 hover:-translate-y-1 hover:scale-110 font-inter"
          id="news"
          style={{ color: SelectedId === 'news' ? '#933FFE' : 'white' }}
          onClick={() => handleSelect('news')}
        >
          <Link to={'/newsletter'}>Newsletters</Link>
        </li>
      </ul>
      <div>
        <div onClick={handleNav} className="block md:hidden">
          {!nav ? <FaWindowClose size={20} /> : <FaBars size={20} />}
        </div>
      </div>
      <div
        className={
          !nav
            ? 'md:hidden fixed left-0 top-0 w-[70%] h-full border-r border-r-black-900 bg-black placeholder-opacity-100 z-50 ease-in-out duration-500'
            : 'fixed left-[-100%] '
        }
      >
        <h1 className="w-full text-3xl font-bold text-{#00df9a} m-8">
          <a>
            <Link to={'/'}>
              <img src="/images/logo.svg" alt="logo" />
            </Link>
          </a>
        </h1>
        <div className="uppercase p-4 flex flex-column flex-wrap md:hidden">
          <a
            className="w-full p-4 font-inter"
            id="home"
            style={{ color: SelectedId === 'home' ? '#933FFE' : 'white' }}
            onClick={() => handleSelect('home')}
          >
            <Link to={'/'}>Home</Link>
          </a>
          <a
            className="w-full p-4 font-inter"
            id="about"
            style={{ color: SelectedId === 'about' ? '#933FFE' : 'white' }}
            onClick={() => handleSelect('about')}
          >
            <Link to={'/about'}>About</Link>
          </a>
          <a
            className="w-full p-4 font-inter"
            id="event"
            style={{ color: SelectedId === 'event' ? '#933FFE' : 'white' }}
            onClick={() => handleSelect('event')}
          >
            <Link to={'/events'}>Events</Link>
          </a>
          <a
            className="w-full p-4 font-inter"
            id="trail"
            style={{ color: SelectedId === 'trail' ? '#933FFE' : 'white' }}
            onClick={() => handleSelect('trail')}
          >
            <Link to={'/trailblazers'}>Trailblazers</Link>
          </a>
          <a
            className="w-full p-4 font-inter"
            id="merch"
            style={{ color: SelectedId === 'merch' ? '#933FFE' : 'white' }}
            onClick={() => handleSelect('merch')}
          >
            <Link to={'/merch'}>Merch</Link>
          </a>
          <a
            className="w-full p-4 font-inter"
            id="news"
            style={{ color: SelectedId === 'news' ? '#933FFE' : 'white' }}
            onClick={() => handleSelect('news')}
          >
            <Link to={'/newsletter'}>Newsletters</Link>
          </a>
          {/* <a className='w-full p-4 font-inter' href="https://docs.google.com/forms/d/1MzwawBTJ1vxTdvWLrLr0eefStB3TjhTfADIZTnnM1UY/edit">Contact </a>  */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
