import React from 'react';
import logo from '../assets/logo_trans.png';

const Footer = () => {
  return (
    <div className="footer flex flex-row gap-96 justify-around pb-8 px-16">
      <div className="mt-[20px] text-left flex flex-col">
        <img src={logo} className="w-[30%] md:w-[30%]" alt="logo" />
        <p className="w-[168px] text-[10px] font-inter font-normal md:text-[14px] md:h-[148px] md:w-[380px] text-white text-opacity-30 mt-[20px] md:mt-[52px]">
          WAGMI, denoting " We are All Gonna Make It", is a Student-Led Community. It provides a
          vision for a democratized future with like-minded people, who wish to see the revolution
          of decentralization via Web 3.0. Learn, Build and Ship together with the brightest minds
          in India.
        </p>
        <p className="md:w-[200px] text-[8px] font-inter font-normal md:text-[14px]  text-white text-opacity-60 mt-[25px] md:mt-[25px]">
          Contact Us
        </p>
        <a href="tel: 630-779-9043">
          <p className="md:w-[200px] text-[10px] font-inter font-normal md:text-[14px]  text-white text-opacity-30 mt-[10px] md:mt-[10px]">
            Call us on <span className="text-white text-opacity-60"> +91 6307 799 043</span>
          </p>
        </a>
        <a href="mailto: contactus@wagmiclub.in">
          <p className="md:w-[400px] text-[10px] font-inter font-normal md:text-[14px]  text-white text-opacity-30 mt-[5px] md:mt-[5px]">
            Email us on <span className="text-white text-opacity-60">contactus@wagmiclub.in</span>
          </p>
        </a>
      </div>

      <div className="flex flex-col justify-start ">
        <p className="font-inter font-normal text-[12px] mt-[57px] md:text-[14px] md: text-white text-opacity-60 md: md:mt-[112px]">
          <a href="/about">About</a>
        </p>
        <p className="font-inter font-normal text-[12px] mt-[20px] md:text-[14px] md: text-white text-opacity-60 md: md:mt-[52px]">
          <a
            href="https://docs.google.com/forms/d/1bG5zOAPiuC9ZLY7VrOsbEJFq_vLYZ-cod7zDDB0zFmY/edit"
            target="_blank"
          >
            Careers
          </a>
        </p>
        <p className="font-inter font-normal text-[12px] mt-[20px] md:text-[14px] md: text-white text-opacity-60 md: md:mt-[52px]">
          <a href="/events">Events</a>
        </p>
        <p className="font-inter font-normal text-[12px] mt-[20px] md:text-[14px] md: text-white text-opacity-60 md: md:mt-[52px]">
          <a href="/merch">Merch</a>
        </p>
        <p className="font-inter font-normal text-[12px] mt-[20px] md:text-[14px] md: text-white text-opacity-60 md:  md:mt-[52px]">
          <a href="/newsletter">Newsletters</a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
