import React from 'react';
import Cards from './Cards';
import image1 from '../assets/ex1.svg';
import image2 from '../assets/ex2.svg';
import image3 from '../assets/ex3.svg';
import image4 from '../assets/ex4.svg';
import image5 from '../assets/ex5.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useMediaQuery } from 'react-responsive';

const Exclusive = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1020px)'
  });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 770px)' });

  return (
    <div>
      <p className=" max-w-[414px] font-black text-[26px] mt-[100px] text-center md:text-[50px] md:leading-[65px] md:font-black md:max-w-[1076px] md:text-center text-white font-inter md:ml-[13rem] md:pb-[117px] ">
        Access to exclusive channels like:
      </p>

      {isDesktopOrLaptop && (
        <Carousel
          showArrows={true}
          showThumbs={false}
          centerMode={true}
          infiniteLoop={true}
          autoPlay={true}
          centerSlidePercentage={30}
        >
          {cardData.map((item, i) => (
            <Cards title={item.title} key={i} content={item.content} image={item.image} />
          ))}
        </Carousel>
      )}

      {isTabletOrMobile && (
        <div className="mt-[35px]">
          <Carousel
            showArrows={true}
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={true}
            // centerMode={true}
            // centerSlidePercentage={35}
          >
            {cardData.map((item, i) => (
              <Cards title={item.title} key={i} content={item.content} image={item.image} />
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
};

const cardData = [
  {
    title: 'Job & Internship Opportunities in Web 3.0.',
    content: 'Get access to the most exclusive opportunities in the world of Web 3.0.',
    image: image1
  },
  {
    title: 'Well curated resources on Web 3.0. ',
    content: 'One stop for all the resources related to Web 3.0, Blockchain, metaverse, NFTs, etc.',
    image: image2
  },
  {
    title: 'Personalised NFT Drops.',
    content: 'Get your personalized NFT drops from the top creators of WAGMI',
    image: image3
  },
  {
    title: 'WAGMI Merchandises',
    content: 'Get your WAGMI gear here, by showing your enthusiasm for the community.',
    image: image4
  },
  {
    title: '1:1 Interaction with industry leaders.',
    content: 'Gain support and knowledge directly from the industry experts in this domain.',
    image: image5
  }
];

export default Exclusive;
