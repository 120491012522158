import React from 'react';
import mdi from '../assets/mdi.png';
import iitm from '../assets/iitm.png';
import fms from '../assets/fms.png';
import isb from '../assets/isb.png';
import iitg from '../assets/iitg.png';
import iitk from '../assets/iitk.png';
import iitd from '../assets/iitd.png';
import ismd from '../assets/ismd.png';
import clgmob from '../assets/clgmob.png';

const Colleges = () => {
  return (
    <div className=" w-full flex flex-col mb-0 mt-[10rem] md:mt-[30rem]">
      <p className="text-[18px] text-white mx-auto font-clashDisplay font-medium md:text-3xl opacity-80 mb-[32px]">
        Our community members come from
      </p>
      <div>
        <div className="hidden md:w-full md:mt-[60px] md:h-full md:flex md:flex-row">
          <img
            className="object-scale-down h-[50px] md:h-[150px] w-auto mx-auto "
            src={mdi}
            alt="colleges"
          />
          <img
            className="object-scale-down h-[50px]  md:h-[150px] w-auto mx-auto"
            src={iitm}
            alt="colleges"
          />
          <img
            className="object-scale-down h-[50px]  md:h-[150px] w-auto mx-auto"
            src={fms}
            alt="colleges"
          />
          <img
            className="object-scale-down h-[50px]  md:h-[150px] w-auto mx-auto"
            src={isb}
            alt="colleges"
          />
        </div>
        <div className="hidden md:w-full md:mt-[60px] md:h-full md:flex md:flex-row ">
          <img
            className="object-scale-down h-[50px]  md:h-[150px] w-auto mx-auto "
            src={iitg}
            alt="colleges"
          />
          <img
            className="object-scale-down h-[50px] md:h-[150px] w-auto mx-auto"
            src={iitd}
            alt="colleges"
          />
          <img
            className="object-scale-down h-[50px]  md:h-[150px] w-auto mx-auto"
            src={iitk}
            alt="colleges"
          />
          <img
            className="object-scale-down h-[50px]  md:h-[150px] w-auto mx-auto"
            src={ismd}
            alt="colleges"
          />
        </div>
      </div>

      <div className="w-full mt-[1rem] px-8 h-full md:hidden">
        <img src={clgmob} alt="clg-mob-img" />
      </div>
    </div>
  );
};
export default Colleges;
