import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useMediaQuery } from 'react-responsive';
import testim1 from '../assets/testim1.png';
import testim2 from '../assets/testim2.png';
import testim3 from '../assets/testim3.png';
import testim4 from '../assets/testim4.png';
import testim5 from '../assets/testim5.png';
import testim6 from '../assets/testim6.png';
import testim7 from '../assets/testim7.png';

const Testimonials = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1020px)'
  });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 760px)' });

  return (
    <div>
      <div>
        <p className="mt-32 max-w-[414px] font-black text-[26px] text-center md:text-[60px] md:leading-[65px] md:font-black md:max-w-[1076px] md:text-center md:ml-[13rem] mb-[2rem] text-white font-inter md:pb-[117px] ">
          Testimonials
        </p>
      </div>
      {isDesktopOrLaptop && (
        <Carousel
          showArrows={true}
          showThumbs={false}
          centerMode={true}
          // infiniteLoop={true}
          autoPlay={true}
          centerSlidePercentage={50}
        >
          <div className="md:w-[60%]">
            <img src={testim1} />
          </div>
          <div className="md:w-[60%]">
            <img src={testim2} />
          </div>
          <div className="md:w-[60%]">
            <img src={testim3} />
          </div>
          <div className="md:w-[60%]">
            <img src={testim4} />
          </div>
          <div className="md:w-[60%]">
            <img src={testim5} />
          </div>
          <div className="md:w-[60%]">
            <img src={testim6} />
          </div>
          <div className="md:w-[60%]">
            <img src={testim7} />
          </div>
        </Carousel>
      )}
      {isTabletOrMobile && (
        <Carousel showArrows={true} showThumbs={false} infiniteLoop={true} autoPlay={true}>
          <div className="md:w-[50%]">
            <img src={testim1} />
          </div>
          <div className="md:w-[50%]">
            <img src={testim2} />
          </div>
          <div className="md:w-[50%]">
            <img src={testim3} />
          </div>
          <div className="md:w-[50%]">
            <img src={testim4} />
          </div>
          <div className="md:w-[50%]">
            <img src={testim5} />
          </div>
          <div className="md:w-[50%]">
            <img src={testim6} />
          </div>
          <div className="md:w-[50%]">
            <img src={testim7} />
          </div>
        </Carousel>
      )}
    </div>
  );
};

{
  /* <div className=" mt-20 px-14 gap-y-12 gap-x-16 flex flex-row justify-start overflow-x-scroll  md:w-[100%] md:mt-[50px] md:h-full md:flex md:flex-row ">
        <img
          className="object-scale-down mx-auto md:w-[50%] "
          src={testim1}
          alt="rankings"
        />
        <img
          className="object-scale-down  md:w-[50%] mx-auto "
          src={testim2}
          alt="rankings"
        />
        <img
          className="object-scale-down  md:w-[50%] mx-auto "
          src={testim3}
          alt="rankings"
        />
        <img
          className="object-scale-down  md:w-[50%] mx-auto "
          src={testim4}
          alt="rankings"
        />
        <img
          className="object-scale-down  md:w-[50%] mx-auto "
          src={testim5}
          alt="rankings"
        />
        <img
          className="object-scale-down  md:w-[50%] mx-auto "
          src={testim6}
          alt="rankings"
        />
        <img
          className="object-scale-down  md:w-[50%] mx-auto "
          src={testim7}
          alt="rankings"
        />
      </div> */
}

export default Testimonials;
