import React, { useState } from 'react';
import isb_amb from '../assets/isb_amb.png';
import mdi_amb from '../assets/mdi_amb.png';
import ism_amb from '../assets/ism_amb.png';
import iitg_amb from '../assets/iitg_amb.png';
import vector_about from '../assets/vector_about.png';
import isb_block from '../assets/isb_block.png';
import mdi_block from '../assets/mdi_block.png';
import ism_block from '../assets/ism_block.png';
import iitg_block from '../assets/iitg_block.png';
import Footer from './Footer';

const College = ({ item }) => {
  const [showPeople, setShowPeople] = useState(false);
  const showPeopleOnClick = () => {
    setShowPeople(!showPeople);
  };
  return (
    <div className="flex flex-col">
      <img
        className="object-scale-down  w-auto mx-auto hover:-translate-y-1 hover:scale-107 transition ease-in-out"
        src={item?.clg}
        alt="rankings"
        onClick={showPeopleOnClick}
      />
      <div>
        {showPeople === true && (
          <div className="people-block md:mt-[2rem]">
            <img src={item?.image} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};

const Trailblazers = () => {
  const People = [
    {
      clg: isb_amb,
      image: isb_block
    },
    {
      clg: mdi_amb,
      image: mdi_block
    },
    {
      clg: ism_amb,
      image: ism_block
    },
    {
      clg: iitg_amb,
      image: iitg_block
    }
  ];

  return (
    <div className="trail-page">
      <div className="text-center md:pt-[118px]  md:text-center flex flex-col">
        <p className=" text-white text-2xl text-[12px] lead-[18px] font-inter md:font-black  md:mt-[100px]  md:  md:text-[54px] md:text-6xl bg-clip-text md:text-white">
          Meet our
          <span className="bg-gradient-to-r text-transparent text-2xl bg-clip-text from-[#E33CD7] to-[#19C8FF] lead-[18px] font-inter md:font-black max-w-[120px] md:mt-[100px]  md:max-w-[620px]  md:text-[54px] md:text-transparent text-6xl bg-clip-text md:from-[#E33CD7] md:to-[#19C8FF]">
            {' '}
            Trailblazers{' '}
          </span>{' '}
        </p>
        <br />
        <p className="trail-info font-inter text-[18px] lead-[18px]  md:font-inter md:text-[24px]">
          Meet our primary representatives from all over the country!
        </p>
      </div>

      <div className="mt-[50px] mb-8 px-8 gap-[2rem] grid grid-cols-1  md:w-full md:h-full  md:mt-[100px] md:grid md:grid-cols-2 md:grid-rows-2 ">
        {People.map((item) => {
          return <College item={item} />;
        })}
      </div>

      <div className="  w-[100%] md:h-[100px] md:flex md:place-items-center ">
        <img className=" w-auto mx-auto " src={vector_about} alt="desc" />
      </div>

      <Footer />
    </div>
  );
};

export default Trailblazers;
