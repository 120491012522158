import React from 'react';

const Cards = (props) => {
  return (
    <div key={props.key}>
      <div className="flex flex-col justify-center h-[349px] w-[380px] bg-[#1A1B23] rounded-3xl px-6 py-6">
        <img src={props.image} className="h-[80px] w-[80px text-center] mt-[]" />
        <h1 className="font-inter text-white text-3xl font-extrabold text-center py-4 max-w-[306px]">
          {props.title}
        </h1>
        <p className="font-inter text-[#898CA9] text-center font-normal py-4">{props.content}</p>
      </div>
    </div>
  );
};
export default Cards;
