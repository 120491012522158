import React, { useState } from 'react';
import isbh from '../assets/isbh.png';
import mdiw from '../assets/mdiw.png';
import wagmin from '../assets/wagmin.png';
import nftt from '../assets/nftt.png';
import isbm from '../assets/isbm.png';
import faceoff from '../assets/faceoff.png';
import wallstrt from '../assets/wallstrt.png';
import quant from '../assets/quant.png';
import equity from '../assets/equity.png';
import calendar from '../assets/calendar.svg';
import vector_about from '../assets/vector_about.png';
import Footer from './Footer';
import EventDetails from './EventDetails';

const Events = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);
  const eventData = [
    {
      title: 'Quant research Project',
      content:
        'A general real life problem statement which should be such that the implementation and solution can be carried out individually as an...',
      image: quant,
      date: '31 Jan 2023'
    },
    {
      title: 'Equity Research Challenge',
      content:
        'Hosted by IIT Guwahati, the competition includes three stages namely Finance Quiz round, Equity report submission and...',
      image: equity,
      date: '27 Jan 2023'
    },
    {
      title: 'Wall Street Trading Competition',
      content:
        'IIT Kanpur hosted a Wall Street Trading Comeptition which saw a traction of 500+ students over a span of 3 days.Trading wa...',
      image: wallstrt,
      date: '8 Jan 2023'
    },
    {
      title: 'College Face-offs',
      content:
        'Facilitating face-offs between colleges on the form of trivia, case competition, extempore and various other competitions.',
      image: faceoff,
      date: '19 Dec 2022'
    },
    {
      title: 'NFT Trivia',
      content:
        "FanTiger is India's largest NFT marketplace. NFTs possess a unique ability to create value for both digital and real world entities...",
      image: nftt,
      date: '29 Nov 2022'
    },
    {
      title: 'AMA session',
      content:
        'It was a very insightful session covering the various aspects of Web3 including explanations on Web1, Web2 and blockchain...',
      image: mdiw,
      date: '3 Dec 2022'
    },
    {
      title: 'WAGMI Nights',
      content:
        'WAGMI Nights is an offline set up in each of the college, where students get together and discuss about Web3 domain, share ideas..',
      image: wagmin,
      date: '30 Oct 2022'
    },
    {
      title: 'Blockchain Event',
      content:
        'Blockchain session being a resounding success, the Ambassadors received requests for more such sessions. One of the... ',
      image: isbm,
      date: '4 Aug 2022'
    },
    {
      title: 'NFT Event',
      content:
        ' Educating students about wallets and NFTs. Most individuals created their first wallets. The Ambassadors solved attendees...',
      image: isbh,
      date: '3 Aug 2022'
    }
  ];

  return (
    <>
      {showDetails === true ? (
        <EventDetails eventDetails={eventDetails} />
      ) : (
        <div className="events-page">
          <div>
            <p className=" max-w-[414px] font-black text-[26px]  text-center md:text-[50px] md:leading-[65px] md:font-black md:max-w-[1076px] md:text-left md:pt-[70px] text-white font-inter md:ml-[100px] ">
              Past Events
            </p>
          </div>
          <div className="px-8 my-8 md:grid  grid-cols-3 grid-rows-3">
            {eventData.map((eventDetail) => {
              // console.log(event, "evetns");
              return (
                <div className=" md:w-full md:h-full  text-white  md:mt-[50px] md:ml-[50px] md:pr-[100px] md:gap-6 mb-8 ">
                  <div class="max-w-sm rounded overflow-hidden shadow-lg">
                    <img
                      class="w-full h-full"
                      src={eventDetail?.image}
                      alt="Sunset in the mountains"
                    />
                    <div class="rounded bg-gray-600 my-1 px-3 py-4">
                      <div class="font-bold text-xl mb-2">{eventDetail?.title}</div>
                      <p class="py-3 text-base">{eventDetail?.content}</p>
                      <div className="flex flex-row justify-between">
                        <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mt-3">
                          {/* <img className="w-full h-full" src={calendar} alt="" /> */}
                          {eventDetail?.date}
                        </span>
                        <a
                          className="py-2 cursor-pointer text-white"
                          onClick={() => {
                            setEventDetails(eventDetail);
                            setShowDetails(true);
                          }}
                        >
                          See More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="hidden px-8 grid gap-[2rem] mb-5 grid-cols-2 grid-rows-4">
            {eventData.map((eventDetail) => {
              // console.log(event, "evetns");
              return (
                <div className=" md:w-full md:h-full  text-white  md:mt-[50px] md:ml-[50px] md:pr-[100px] md:gap-6 ">
                  <div class="max-w-sm rounded overflow-hidden shadow-lg">
                    <img
                      class="w-full h-full"
                      src={eventDetail?.image}
                      alt="Sunset in the mountains"
                    />
                    <div class="rounded bg-gray-600 my-1 px-3 py-4">
                      <div class="font-bold text-xl mb-2">{eventDetail?.title}</div>
                      <p class="text-base">{eventDetail?.content}</p>
                      <div className="flex flex-row justify-between">
                        <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mt-3">
                          {/* <img className="w-full h-full" src={calendar} alt="" /> */}
                          {eventDetail?.date}
                        </span>
                        <a
                          className="py-2 cursor-pointer text-white"
                          onClick={() => {
                            setEventDetails(eventData);
                            setShowDetails(true);
                          }}
                        >
                          See More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="md:mt-[40px] w-[100%] md:h-[100px] md:flex md:place-items-center ">
            <img className=" w-auto mx-auto " src={vector_about} alt="desc" />
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Events;
