import React, { useState } from 'react';
import ftx1 from '../assets/ftxxx.jpeg';
import ftx2 from '../assets/ftxx2.jpeg';
import vector_about from '../assets/vector_about.png';
import Footer from './Footer';
import NewsletterDetails from './NewsLetterDetails';

const Newsletters = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [newsDetails, setNewsDetails] = useState(null);

  const newsData = [
    {
      title: 'Industry & Trends Update',
      content: 'Initiative by MDI-Gurgaon',
      image: ftx2
    },
    {
      title: 'Fall of FTX',
      content: 'Initiative by MDI-Gurgaon',
      image: ftx1
    }
  ];
  return (
    <>
      {showDetails === true ? (
        <NewsletterDetails newsDetails={newsDetails} />
      ) : (
        <div className="events-page">
          <div>
            <p className=" max-w-[414px] font-black text-[26px]  text-center md:text-[50px] md:leading-[65px] md:font-black md:max-w-[1076px] md:text-left md:pt-[70px] text-white font-inter md:ml-[100px] ">
              Past NewsLetters
            </p>
          </div>
          <div className="px-8 my-8 md:grid  grid-cols-3 grid-rows-3">
            {newsData.map((newsDetail) => {
              // console.log(event, "evetns");
              return (
                <div className=" md:w-full md:h-full  text-white  md:mt-[50px] md:ml-[50px] md:pr-[100px] md:gap-6 mb-8 ">
                  <div class="max-w-sm rounded overflow-hidden shadow-lg">
                    <img
                      class="w-full h-full"
                      src={newsDetail?.image}
                      alt="Sunset in the mountains"
                    />
                    <div class="rounded bg-gray-600 my-1 px-3 py-4">
                      <div class="font-bold text-xl mb-2">{newsDetail?.title}</div>
                      <p class="py-3 text-base">{newsDetail?.content}</p>
                      <div className="flex flex-row justify-between">
                        <a
                          className="py-2 cursor-pointer text-white"
                          onClick={() => {
                            setNewsDetails(newsDetail);
                            setShowDetails(true);
                          }}
                        >
                          See More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="hidden px-8 grid gap-[2rem] mb-5 grid-cols-2 grid-rows-4">
            {newsData.map((newsDetail) => {
              // console.log(event, "evetns");
              return (
                <div className=" md:w-full md:h-full  text-white  md:mt-[50px] md:ml-[50px] md:pr-[100px] md:gap-6 ">
                  <div class="max-w-sm rounded overflow-hidden shadow-lg">
                    <img
                      class="w-full h-full"
                      src={newsDetail?.image}
                      alt="Sunset in the mountains"
                    />
                    <div class="rounded bg-gray-600 my-1 px-3 py-4">
                      <div class="font-bold text-xl mb-2">{newsDetail?.title}</div>
                      <p class="text-base">{newsDetail?.content}</p>
                      <div className="flex flex-row justify-between">
                        <a
                          className="py-2 cursor-pointer text-white"
                          onClick={() => {
                            setNewsDetails(newsData);
                            setShowDetails(true);
                          }}
                        >
                          See More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="md:mt-[-55rem] w-[100%] md:h-[100px] md:flex md:place-items-center ">
            <img className=" w-auto mx-auto " src={vector_about} alt="desc" />
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Newsletters;
