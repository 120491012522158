import React from 'react';
import white_tee from '../assets/white_tee.png';
import black_tee from '../assets/black_tee.png';
import card from '../assets/card.png';
import socket from '../assets/socket.png';
import headphones from '../assets/headphones.png';
import kit from '../assets/kit.png';
import merch_gift from '../assets/merch_gift.png';
import vector_about from '../assets/vector_about.png';
import Footer from './Footer';

const Merch = () => {
  return (
    <div className="merch-page px-8">
      <div className="flex flex-col md:flex md:flex-row md:gap-20">
        <div className=" md:h-screen md:pt-[118px] text-center md:ml-[102px] flex flex-col">
          <p className="mt-[2rem] max-w-[414px] font-black text-[26px]  text-center  md:text-left md:text-[70px] md:leading-[65px] md:font-semibold md:max-w-[640px] text-white font-clashDisplay">
            Merch
          </p>
          <p className="pt-8 text-center text-[22px] lead-[18px] font-inter font-normal md:mt-[30px] md:max-w-[940px] text-white md:pt-0 md:text-left md:text-[26px] md:pl-[0px]">
            Become a <span className="font-semibold">WAGMI </span> member now and get access to our{' '}
            <span className="font-semibold">Official Merch!</span>
          </p>
          <div>
            <button className="md:flex md:flex-row md:justify-center md:text-white md:bg-gradient-to-bl md:from-[#18C8FF] md:to-[#933FFE] md:hover:from-pink-500 md:hover:to-yellow-500 md:w-[240px] md:rounded-[10px] md:text-[16px]  md:mx-0 md:font-inter md:font-semibold md:py-4 md:mt-[40px] md:ease-in-out md:duration-500 md:hover:-translate-y-1  flex flex-row justify-center text-white bg-gradient-to-bl from-[#18C8FF] to-[#933FFE] hover:from-pink-500 hover:to-yellow-500 ml-[2rem] w-[250px] rounded-[10px] text-[16px] mx-0 font-inter font-semibold py-4 mt-[40px] ease-in-out duration-500 hover:-translate-y-1 ">
              Collaborate with WAGMI
            </button>
          </div>
        </div>
        <img
          className="md:w-[35%] hover:-translate-y-1 hover:scale-110 transition ease-in-out"
          src={merch_gift}
          alt=""
        />
      </div>

      <div className=" mt-[2rem] w-[100%] md:w-[1409px] md:h-[100px] md:flex md:place-items-center ">
        <img className=" w-auto mx-auto " src={vector_about} alt="desc" />
      </div>

      <div>
        <p className="md:text-center text-center mt-[100px] text-[20px] lead-[18px] font-inter md:font-black md:mt-[100px] text-white motion-safe:animate-bounce  md:text-[54px] ">
          Exclusive{' '}
          <span className="text-4xl text-transparent from-[#E33CD7] to-[#19C8FF] md:text-transparent md:text-6xl bg-clip-text bg-gradient-to-r  md:from-[#E33CD7] md:to-[#19C8FF]">
            WAGMI{' '}
          </span>{' '}
          T-shirts{' '}
        </p>
      </div>

      <div className="md:flex md:flex-row md:mt-[15rem]">
        <div className="mt-14 flex flex-col md:flex md:flex-col md:ml-[114px]">
          <div>
            <img
              src={white_tee}
              className=" h-[428px] md:w-[796px] hover:-translate-y-1 md:mt-[-200px] md:ml-[-20px] hover:scale-110 transition ease-in-out "
              alt="/"
            />
          </div>
          <div className=" md:pt-[118px] text-center">
            <p className="text-center font-black text-[26px] md:text-center md:mt-[-6rem] md:text-left md:text-[48px] md:leading-[65px] md:font-semibold text-[#B982FF] font-inter ">
              Ambassadors <span className="text-white md:text-white">T-shirt</span>
            </p>
          </div>
        </div>

        <div className="mt-[5rem]  md:ml-[12rem] flex flex-col md:flex md:flex md:flex-col">
          <div className="">
            <img
              src={black_tee}
              className="h-[428px] md:w-[796px] hover:-translate-y-1 md:mt-[-220px] md:ml-[-20px] hover:scale-110 transition ease-in-out "
              alt="/"
            />
          </div>
          <div className="md:pt-[118px] text-center flex flex-col">
            <p className="text-center font-black text-[26px] md:text-center md:mt-[-6rem] md:text-left md:text-[48px] md:leading-[65px] md:font-semibold text-[#B982FF] font-inter ">
              Members <span className="text-white md:text-white">T-shirt</span>
            </p>
          </div>
        </div>
      </div>

      <div className="md:mt-[3rem] mt-[3rem] w-[100%] md:w-[1409px] md:h-[100px] md:flex md:place-items-center ">
        <img className=" w-auto mx-auto " src={vector_about} alt="desc" />
      </div>

      <div>
        <p className="mt-[5rem] text-center text-[20px] lead-[18px] font-inter md:font-black  md:mt-[100px] text-white motion-safe:animate-bounce  md:text-[54px] ">
          Exclusive{' '}
          <span className="text-transparent text-3xl  bg-gradient-to-r from-[#E33CD7] to-[#19C8FF] md:text-transparent md:text-6xl bg-clip-text md:bg-gradient-to-r  md:from-[#E33CD7] md:to-[#19C8FF]">
            WAGMI{' '}
          </span>{' '}
          Merch{' '}
        </p>
      </div>

      <div className="mt-[2rem] flex flex-col gap-12 md:mt-[250px] md:flex md:flex-col md:gap-5 md:ml-[114px]">
        <div className="flex flex-col gap-12 md:flex md:flex-row md:gap-16">
          <img
            src={kit}
            className="w-[100%] md:w-[50%] hover:-translate-y-1 md:mt-[-200px] md:ml-[-100px] hover:scale-110 transition ease-in-out "
            alt="/"
          />

          <img
            src={headphones}
            className="w-[100%] md:w-[50%] hover:-translate-y-1 md:mt-[-200px] md:ml-[-0px] hover:scale-110 transition ease-in-out "
            alt="/"
          />
        </div>
        <div className=" flex flex-col gap-12 md:flex md:flex-row md:gap-16">
          <img
            src={card}
            className="w-[100%] md:w-[50%] hover:-translate-y-1 md:mt-[50px] md:ml-[-100px] hover:scale-110 transition ease-in-out "
            alt="/"
          />
          <img
            src={socket}
            className="w-[100%] md:w-[50%] hover:-translate-y-1 md:mt-[50px] md:ml-[0px]  hover:scale-110 transition ease-in-out "
            alt="/"
          />
        </div>
      </div>

      {/* <div className="flex flex-row justify-center md:justify-left">
        <button className="md:flex md:flex-row md:justify-center md:text-white md:bg-gradient-to-bl md:from-[#18C8FF] md:to-[#933FFE] md:hover:from-pink-500 md:hover:to-yellow-500 md:w-[240px] md:rounded-[10px] md:text-[16px]  md:mx-0 md:font-inter md:font-semibold md:py-4 md:mt-[40px] md:ease-in-out md:duration-500 md:hover:-translate-y-1  flex flex-row justify-center text-white bg-gradient-to-bl from-[#18C8FF] to-[#933FFE] hover:from-pink-500 hover:to-yellow-500 w-[240px] rounded-[10px] text-[16px] mx-0 font-inter font-semibold py-4 mb-[40px] ease-in-out duration-500 hover:-translate-y-1 ">
          Collaborate with WAGMI
        </button>
      </div> */}

      <div className="mt-[1rem] md:mt-[40px] w-[100%] md:w-[1409px] md:h-[100px] md:flex md:place-items-center ">
        <img className=" w-auto mx-auto " src={vector_about} alt="desc" />
      </div>

      <Footer />
    </div>
  );
};

export default Merch;

{
  /* <button className="hidden md:flex md:flex-row md:justify-center md:text-white md:bg-gradient-to-bl md:from-[#18C8FF] md:to-[#933FFE] md:hover:from-pink-500 md:hover:to-yellow-500 md:w-[240px] md:rounded-[10px] md:text-[16px]  md:mx-0 md:font-inter md:font-semibold md:py-4 md:mt-[40px] md:ease-in-out md:duration-500 md:hover:-translate-y-1">
          Collaborate with WAGMI
        </button> */
}
