import React from 'react';
import Footer from './Footer';

const Coming = () => {
  return (
    <div className="coming-page">
      <p className="text-[36px] text-white mx-auto font-clashDisplay font-black md:pl-[470px] md:py-[340px] md:text-[60px] mb-[32px]">
        Coming soon...
      </p>
      <div className="pt-[200px]">
        <Footer />
      </div>
    </div>
  );
};
export default Coming;
