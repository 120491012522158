import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import Navbar from './components/Navbar';
import Events from './components/Events';
import Coming from './components/Coming';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import About from './components/About';
import Home from './components/Home';
import Merch from './components/Merch';
import Trailblazers from './components/Trailblazers';
import Newsletters from './components/Newsletters';

function App() {
  return (
    <Router>
      <Navbar />
      <div className="App">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/events" component={Events} />
          <Route path="/merch" component={Merch} />
          <Route path="/coming" component={Coming} />
          <Route path="/trailblazers" component={Trailblazers} />
          <Route path="/newsletter" component={Newsletters} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
